/* @flow */
import React, {Component} from 'react';
import autobind from 'class-autobind';
import RichTextEditor from 'react-rte';


export default class Editor extends Component {

  constructor() {
    super();
    autobind(this);
  }
  

  _onChange(value) {
    this.props.onArticleInputChange(value) 
  }

  render() {
    const {value} = this.props;

    return (
          <RichTextEditor
            rootStyle={{minHeight: "300px"}}
            editorStyle={{minHeight: "300px"}}
            value={value}
            onChange={this._onChange}
          />
    );
  }
}