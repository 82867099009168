import * as Yup from 'yup';
import { useContext, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import { update, getById } from '../../api/Category';
import { LoadingContext } from '../../hoc/loading';
import { MessageContext } from '../../hoc/messageNotification';

// ----------------------------------------------------------------------

export default function CategoryEdit() {
  const navigate = useNavigate();
  const {setLoading} = useContext(LoadingContext);
  const {setErrorMessage} = useContext(MessageContext);

  const { id } = useParams();

  const getCategoryById = async () => {
      try {
      const res = await getById(id)
      setValues(res)
      } catch (e) {
        setErrorMessage(e)
      }
      setLoading(false)
  }

  useEffect(() => {
    getCategoryById()
  }, [])

  const formik = useFormik({
    initialValues: {
      title: '',
      subtitle: '',
      description: '',
      active: false,
      image: '',
      subscriptionType: ''
    },
    onSubmit: () => {
      onCategoryEdit(values)
      navigate('/dashboard/app');
    },
  });

  const onCategoryEdit = async (values) => {
    try {
      setLoading(true)
      await update(values)
    } catch (e) {
      setErrorMessage(e)
    }
    setLoading(false)
  }

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setValues} = formik;

  const inputs = [
    {id: "title", label: "Title"},
    {id: "subtitle", label: "Subtitle"},
    {id: "description", label: "Description"}
  ]

  return (
    <FormikProvider value={formik}>
        <Stack spacing={3} sx={{marginBottom: 3}}>
          <h1>
            Edit category
          </h1>
        </Stack>
      <Form noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {inputs.map(e => ( 
             <TextField
                  key={e.id}
                  fullWidth
                  autoComplete={e.id}
                  type="text"
                  label={e.label}
                  {...getFieldProps(e.id)}
                  error={Boolean(touched[e.id] && errors[e.id])}
                  helperText={touched[e.id] && errors[e.id]}
                />
          )
          )}
        </Stack>

        <LoadingButton sx={{marginTop: 3}} fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Edit
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

