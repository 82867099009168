import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const AlertDialog = ({content, userId, isDialogueOpen, handleClose}) => {
  return (
    <div>
      <Dialog
        open={isDialogueOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            {content.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            {Object.entries(content.actions).map(([key, value]) => <Button key={key + value + Math.random()} onClick={() => value(userId)}>{key}</Button>)}
        </DialogActions>
      </Dialog>
    </div>
  );
}