import { post, get, put, deletion } from "./index"

export const create = (values) => {
    return new Promise((resolve, rej) => {
        post('api/hashtag/create', values)
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }

  export const update = (values) => {
    return new Promise((resolve, rej) => {
        put('api/hashtag/update', values)
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }

  export const getById = (id) => {
    return new Promise((resolve, rej) => {
        get(`api/hashtag/${id}`)
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }

  export const getAll = () => {
    return new Promise((resolve, rej) => {
        get('api/hashtag/list')
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }

  export const remove = (id) => {
    return new Promise((resolve, rej) => {
        deletion(`api/hashtag/${id}`)
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }