import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, Checkbox, InputLabel, TextField, FormControlLabel, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import { LoadingContext } from '../../hoc/loading';
import { MessageContext } from '../../hoc/messageNotification';
import { create } from '../../api/Category';
import { post } from '../../api';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function CreateCategory() {
  const navigate = useNavigate();

  const [category, setCategory] = useState('');
  const [active, setActive] = useState(false);
  const { setLoading } = useContext(LoadingContext);
  const { setErrorMessage } = useContext(MessageContext);

  const CategorySchema = Yup.object().shape({
    title: Yup.string().required('title is required'),
    subtitle: Yup.string().required('Subtitle is required'),
    description: Yup.string().required('Descriptions is required'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      subtitle: '',
      description: '',
      active: false,
      image: '',
    },
    validationSchema: CategorySchema,
    onSubmit: () => {
      createCategory();
      navigate('/dashboard/app', { replace: true });
    },
  });

  const createCategory = async () => {
    try {
      setLoading(true);
      await create(values);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleSelectChange = (e, type) => {
    const input = new FormData();
    const file = document.getElementById('file-input').files[0];
    switch (type) {
      case 'image':
        input.append('file', file);
        input.append('name', file.name)
        post('api/image/create', input).then((res) => {
          values.image = res.data.url;
        });
        break;
      case 'active':
        values.active = e.target.checked;
        setActive(e.target.checked);
        break;
      default:
        break;
    }
  };

  const inputs = [
    { id: 'title', label: 'Title' },
    { id: 'subtitle', label: 'Subtitle' },
    { id: 'description', label: 'Description' },
  ];

  return (
    <FormikProvider value={formik}>
      <Stack spacing={3} sx={{ marginBottom: 3 }}>
        <h1>Create category</h1>
      </Stack>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Button variant="contained" sx={{ my: 3 }} component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
            Category image
            <input type="file" id="file-input" onChange={(e) => handleSelectChange(e, 'image')} hidden />
          </Button>
        <Stack spacing={3}>
          {inputs.map((e) => (
            <TextField
              fullWidth
              autoComplete={e.id}
              type="text"
              placeholder={e.label}
              {...getFieldProps(e.id)}
              error={Boolean(touched[e.id] && errors[e.id])}
              helperText={touched[e.id] && errors[e.id]}
            />
          ))}

          {/* <FormControl fullWidth>
            <InputLabel id={`select-category`}>Category</InputLabel>
                <Select
                labelId={`select-category`}
                label='Category'
                value={category}
                onChange={(e) => handleSelectChange(e, 'category')}
                >
                  <MenuItem value="COOKING">Cooking</MenuItem>
                </Select>
            </FormControl> */}

          <FormControlLabel
            label="Active"
            control={<Checkbox checked={active} onChange={(e) => handleSelectChange(e, 'active')} />}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            placeholder="Remember me"
          /> */}
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Create
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
