import { useContext, useEffect, useState } from 'react';
// material
import {
  Card,
  Stack,
  Container,
  Typography,
  TextField,
  Tabs,
  Tab,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
} from '@mui/material';
// components
import { useFormik } from 'formik';
import { MessageContext } from '../../hoc/messageNotification';
import { LoadingContext } from '../../hoc/loading';
import Page from '../../components/Page';
import { TabPanel } from '../../components/AddImageModal';
import Iconify from '../../components/Iconify';
import { getAll, updateList } from '../../api/Options';
import { create } from '../../api/Image';
import { AlertDialog } from '../../components/AlertDialog';

export default function Options() {
  const [tabIndex, setTabIndex] = useState(0);
  const [optionsFetch, setOptionsFetch] = useState([]);
  const [generalOptions, setGeneralOptions] = useState([]);
  const [categorySorting, setCategorySorting] = useState();
  const [footerOptions, setFooterOptions] = useState([]);
  const [headerOptions, setHeaderOptions] = useState([]);
  const [selectedImageElId, setSelectedImageElId] = useState([]);
  const [tempImages, setTempImages] = useState({});
  const [alert, setAlert] = useState(null)
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);


  const { setLoading } = useContext(LoadingContext);
  const { setErrorMessage, setSuccessMessage } = useContext(MessageContext);

  const formik = useFormik({
    initialValues: {
      title: '',
      subtitle: '',
      address: '',
      linkFacebook: '',
      linkInstagram: '',
      linkYoutube: '',
      linkTwitter: '',
      linkPinterest: '',
      linkLinkedin: '',
      headerFacebookIcon: '',
      headerInstagramIcon: '',
      headerTwitterIcon: '',
      headerYoutubeIcon: '',
      headerPinterestIcon: '',
      headerLinkedinIcon: '',
      categorySorting: '',
      footerAbout: '',
      footerOfficialPublicSpots: '',
      footerOfficialPublicSpot2: '',
      footerOfficialPublicSpot3: '',
      footerOfficialPublicSpot4: '',
      footerOfficialPublicSpot5: '',
      footerOfficialPublicSpot6: '',
      footerOfficialPublicSpot7: '',
      footerOfficialPublicSpot8: '',
      footerOfficialPublicSpot9: '',
      footerReducePrintwasteText: '',
      footerNewsletterText: '',
      footerContact: '',
      footerContactAnschrift: '',
      footerContactPublisher: '',
      footerFacebookIcon: '',
      footerInstagramIcon: '',
      footerTwitterIcon: '',
      footerYoutubeIcon: '',
      footerPinterestIcon: '',
      footerLinkedinIcon: '',
      logo: '',
    },
    onSubmit: () => {
      return true;
    },
  });

  const handleChangeTabIndex = (event, newValue) => {
    setTabIndex(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const getOptions = async () => {
    const res = await getAll();
    const headericonsLinksObjs = res.filter((e) => e.prop.includes('header') || e.prop.includes('link'));
    const generalOptionsObjs = res.filter((e) => e.prop.includes('title') || e.prop.includes('address'));
    const categorySorting = res.filter((e) => e.prop.includes('categorySorting'));
    const footerOptionsRes = res.filter((e) => e.prop.includes('footer'));
    const imagesRes = res.filter((e) => e.prop.includes('Icon') || e.prop.includes('logo'))
    imagesRes.forEach(({prop, value}) => {tempImages[prop] = value})
    setTempImages(tempImages)
    setFooterOptions(footerOptionsRes);
    setGeneralOptions(generalOptionsObjs);
    setCategorySorting(categorySorting[0].value);
    setHeaderOptions(headericonsLinksObjs);
    parseFetchDataToObj(res);
    setOptionsFetch(res);
  };

  const handleImageChange = async (inputId) => {
    const input = new FormData();
      const file = document.getElementById(inputId).files[0];
      input.append('file', file);
      input.append('name', file.name);
    try {
      setLoading(true);
      const img = await create(input);
      setSuccessMessage({'Success': ''})
      setTempImages({...tempImages, [inputId]: img.url});
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const handleAlertDialogOpen = (id) => {
    setSelectedImageElId(id)
    setAlertDialogOpen(true);
  };

  const handleAlertDialogClose = () => {
    setAlertDialogOpen(false);
  };

  const content = {
    title: 'Are you sure to set this Image?',
    description: '',
    actions: { Disagree: handleAlertDialogClose, Agree: (id) => {handleImageChange(id); handleAlertDialogClose()} },
  };

  const updateData = (targetArr) => {
    const result = [];

    headerOptions.forEach((el) => {
      el.value = values[el.prop];
    });
    footerOptions.forEach((el) => {
      el.value = values[el.prop];
    });
    generalOptions.forEach((el) => {
      el.value = values[el.prop];
    });

      optionsFetch.forEach((a1) => {
        if(tempImages[a1.prop]) {
          a1.value = tempImages[a1.prop]
        }
        result.push(a1);
        targetArr.forEach((a2) => {
          if (a1.id === a2.id) {
            result.push(a2);
          }
        });
      });
      const resultWithoutDuplicates = result.filter(
        (value, index, self) => index === self.findIndex((t) => t.id === value.id && t.id === value.id)
      );
      return resultWithoutDuplicates
  }

  const updateSocials = async () => {
    try {
      setLoading(true);
      const resultWithoutDuplicates = updateData(headerOptions)
      const indexOfCategorySorting = resultWithoutDuplicates.findIndex((e) => e.prop === 'categorySorting');
      resultWithoutDuplicates[indexOfCategorySorting].value = categorySorting;
      await updateList(resultWithoutDuplicates);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
    await getOptions();
  };

  const updateCategorySorting = (e) => {
    setCategorySorting(e.target.value);
    values.categorySorting = e.target.value;
  };

  const parseFetchDataToObj = (arr) => {
    let formObj = {};

    arr.forEach((item) => {
      formObj = { ...formObj, [item.prop]: item.value };
    });
    setValues(formObj);
  };

  useEffect(() => {
    getOptions();
  }, []);

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setValues, setFieldValue } = formik;

  const inputs = [
    { id: 'title', label: 'Website Title' },
    { id: 'subtitle', label: 'Website Meta Subtitle' },
    { id: 'address', label: 'Address' },
  ];

  const socialInputs = [
    { id: 'linkFacebook', label: 'Facebook' },
    { id: 'linkInstagram', label: 'Instagram' },
    { id: 'linkYoutube', label: 'Youtube' },
    { id: 'linkTwitter', label: 'Twitter' },
  ];

  const footerInputs = [
    { label: 'Footer about block' },
    { id: 'footerNewsletterText', label: 'Footer Newsletter Text'},
    { id: 'footerAbout', label: 'Footer about text' },
    { id: 'footerReducePrintwasteText', label: 'Footer Reduce Printwaste Text'},
    { label: 'Footer official spots block' },
    { id: 'footerOfficialPublicSpots', label: 'Footer official public spot 1' },
    { id: 'footerOfficialPublicSpot2', label: 'Footer official public spot 2' },
    { id: 'footerOfficialPublicSpot3', label: 'Footer official public spot 3' },
    { id: 'footerOfficialPublicSpot4', label: 'Footer official public spot 4' },
    { id: 'footerOfficialPublicSpot5', label: 'Footer official public spot 5' },
    { id: 'footerOfficialPublicSpot6', label: 'Footer official public spot 6' },
    { id: 'footerOfficialPublicSpot7', label: 'Footer official public spot 7' },
    { id: 'footerOfficialPublicSpot8', label: 'Footer official public spot 8' },
    { id: 'footerOfficialPublicSpot9', label: 'Footer official public spot 9' },
    { label: 'Footer contact block' },
    { id: 'footerContact', label: 'Footer contact' },
    { id: 'footerContactAnschrift', label: 'Footer contact anschrift' },
    { id: 'footerContactPublisher', label: 'Footer contact publisher' },
  ];

  return (
    <Page title="Options">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Options
          </Typography>
        </Stack>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabIndex}
            onChange={handleChangeTabIndex}
            aria-placeholder="basic tabs example"
            sx={{
              flexDirection: 'column',
            }}
          >
            <Tab label="Global settings" {...a11yProps(0)} />
            <Tab label="Logo settings" {...a11yProps(1)} />
            <Tab label="Header" {...a11yProps(2)} />
            <Tab label="Footer" {...a11yProps(3)} />
          </Tabs>
        </Box>

        <Card sx={{ my: 2 }}>
          <TabPanel value={tabIndex} index={0}>
            <Stack
              sx={{
                padding: '10px',
              }}
              spacing={3}
            >
              {inputs.map((e) => (
                <TextField
                  key={e.id}
                  fullWidth
                  autoComplete={e.id}
                  type="text"
                  label={e.label}
                  {...getFieldProps(e.id)}
                  error={Boolean(touched[e.id] && errors[e.id])}
                  helpertext={touched[e.id] && errors[e.id]}
                />
              ))}
            </Stack>
            {/* <Button variant="contained" component="label" onClick={updateGeneral}>
              Save
            </Button> */}
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>
            <Stack
              sx={{
                padding: '10px',
              }}
              spacing={3}
            >
              <h4>Logo image</h4>
              <img src={tempImages.logo} alt="Logo" width={300} height={150} />
            </Stack>
            <Box
              sx={{ my: 3, display: 'flex', flexDirection: 'column' }}
            >
            <Button variant="contained" sx={{width: "200px"}} component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
              Upload New Logo
              <input type="file" id="logo" onChange={(e) => handleAlertDialogOpen('logo')} hidden />
            </Button>
            {/* <Button sx={{width: "50px", my: 3}} variant="contained" component="label" onClick={updateSocials}>
              Save
            </Button> */}
            </Box>
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <Stack
              sx={{
                padding: '10px',
              }}
              spacing={3}
            >
              <Typography component="h1" sx={{ fontWeight: '600', fontSize: '32px' }}>
                Header categories sorting
              </Typography>
              <FormControl fullWidth>
                <InputLabel id={`category-sorting`}>Category sorting</InputLabel>
                <Select
                  labelId={`category-sorting`}
                  label={'Category sorting'}
                  value={categorySorting}
                  onChange={(e) => updateCategorySorting(e)}
                  error={Boolean(touched.categorySorting && errors.categorySorting)}
                  helperText={touched.categorySorting && errors.categorySorting}
                >
                  <MenuItem value={'default'}>Default</MenuItem>
                  <MenuItem value={'asc'}>Ascending</MenuItem>
                  <MenuItem value={'desc'}>Descending</MenuItem>
                </Select>
              </FormControl>
              <Typography component="h1" sx={{ fontWeight: '600', fontSize: '32px' }}>
                Social links
              </Typography>
              {socialInputs.map((e) => (
                <TextField
                  key={e.id}
                  fullWidth
                  autoComplete={e.id}
                  type="text"
                  label={e.label}
                  {...getFieldProps(e.id)}
                  error={Boolean(touched[e.id] && errors[e.id])}
                  helperText={touched[e.id] && errors[e.id]}
                />
              ))}
            </Stack>
            <Typography component="h1" sx={{ fontWeight: '600', fontSize: '32px' }}>
            {"Social icons images ( .png or .jpg only, 32x32)"}
            </Typography>
            <Box
              sx={{ my: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '500px' }}
            >
              <Box
              sx={{ my: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '300px' }}
            >
              {/* "headerFacebookIcon".match(/header(.*)Icon/) */}
              <Button variant="contained" component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
                upload Facebook icon
                <input type="file" id="headerFacebookIcon" onChange={(e) => handleAlertDialogOpen('headerFacebookIcon')} hidden />
              </Button>
              <img src={tempImages.headerFacebookIcon} alt="Facebook icon" width={32} height={32}/>
            </Box>
            <Box
              sx={{ my: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '300px' }}
            >
              <Button variant="contained" component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
                upload Youtube icon
                <input type="file" id="headerYoutubeIcon" onChange={(e) => handleAlertDialogOpen('headerYoutubeIcon')} hidden />
              </Button>
              <img src={tempImages.headerYoutubeIcon} alt="Youtube icon" width={32} height={32}/>
            </Box>
            <Box
              sx={{ my: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '300px' }}
            >
              <Button variant="contained" component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
                upload Twitter icon
                <input type="file" id="headerTwitterIcon" onChange={(e) => handleAlertDialogOpen('headerTwitterIcon')} hidden />
              </Button>
              <img src={tempImages.headerTwitterIcon} alt="Twitter icon" width={32} height={32}/>
            </Box>
            <Box
              sx={{ my: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '300px' }}
            >
              <Button variant="contained" component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
                upload Instagram icon
                <input type="file" id="headerInstagramIcon" onChange={(e) => handleAlertDialogOpen('headerInstagramIcon')} hidden />
              </Button>
              <img src={tempImages.headerInstagramIcon} alt="Instagram icon" width={32} height={32}/>
            </Box>
            <Box
              sx={{ my: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '300px' }}
            >
              <Button variant="contained" component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
                upload Linkedin icon
                <input type="file" id="headerLinkedinIcon" onChange={(e) => handleAlertDialogOpen('headerLinkedinIcon')} hidden />
              </Button>
              <img src={tempImages.headerLinkedinIcon} alt="Linkedin icon" width={32} height={32}/>
            </Box>
            <Box
              sx={{ my: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '300px' }}
            >
              <Button variant="contained" component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
                upload Pinterest icon
                <input type="file" id="headerPinterestIcon" onChange={(e) => handleAlertDialogOpen('headerPinterestIcon')} hidden />
              </Button>
              <img src={tempImages.headerPinterestIcon} alt="Pinterest icon" width={32} height={32}/>
            </Box>
            </Box>
            {/* <Button variant="contained" sx={{my:5}} component="label" onClick={updateSocials}>
              Save
            </Button> */}
          </TabPanel>
          <TabPanel value={tabIndex} index={3}>
            <Stack
              sx={{
                padding: '10px',
                my: 2
              }}
              spacing={3}
            >
              {footerInputs.map((e) => {
                if (!e.id) {
                  return (
                    <Typography key={e.label} component="h1" sx={{ fontWeight: '600', fontSize: '32px' }}>
                      {e.label}
                    </Typography>
                  );
                }
                return (
                  <TextField
                    key={e.id}
                    fullWidth
                    autoComplete={e.id}
                    type="text"
                    multiline
                    inputProps={{
                      height: '60px',
                    }}
                    label={e.label}
                    {...getFieldProps(e.id)}
                    error={Boolean(touched[e.id] && errors[e.id])}
                    helperText={touched[e.id] && errors[e.id]}
                  />
                );
              })}
              <Typography component="h1" sx={{ fontWeight: '600', fontSize: '32px' }}>
              {"Social icons images ( .png or .jpg only, 32x32)"}
            </Typography>
               <Box
              sx={{ my: 3, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '500px' }}
            >
              <Box
              sx={{ my: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '300px' }}
            >
              <Button variant="contained" component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
                upload Youtube icon
                <input type="file" id="footerYoutubeIcon" onChange={(e) => handleAlertDialogOpen('footerYoutubeIcon')} hidden />
              </Button>
              <img src={tempImages.footerYoutubeIcon} alt="Youtube icon" width={32} height={32}/>
            </Box>
            <Box
              sx={{ my: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '300px' }}
            >
              <Button variant="contained" component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
                upload Facebook icon
                <input type="file" id="footerFacebookIcon" onChange={(e) => handleAlertDialogOpen('footerFacebookIcon')} hidden />
              </Button>
              <img src={tempImages.footerFacebookIcon} alt="Facebook icon" width={32} height={32}/>
            </Box>
            <Box
              sx={{ my: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '300px' }}
            >
              <Button variant="contained" component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
                upload Twitter icon
                <input type="file" id="footerTwitterIcon" onChange={(e) => handleAlertDialogOpen('footerTwitterIcon')} hidden />
              </Button>
              <img src={tempImages.footerTwitterIcon} alt="Twitter icon" width={32} height={32}/>
            </Box>
            <Box
              sx={{ my: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '300px' }}
            >
              <Button variant="contained" component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
                upload Instagram icon
                <input type="file" id="footerInstagramIcon" onChange={(e) => handleAlertDialogOpen('footerInstagramIcon')} hidden />
              </Button>
              <img src={tempImages.footerInstagramIcon} alt="Instagram icon" width={32} height={32}/>
            </Box>
            <Box
              sx={{ my: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '300px' }}
            >
              <Button variant="contained" component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
                upload Pinterest icon
                <input type="file" id="footerPinterestIcon" onChange={(e) => handleAlertDialogOpen('footerPinterestIcon')} hidden />
              </Button>
              <img src={tempImages.footerPinterestIcon} alt="Pinterest icon" width={32} height={32}/>
            </Box>
            <Box
              sx={{ my: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '300px' }}
            >
              <Button variant="contained" component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
                upload Linkedin icon
                <input type="file" id="footerLinkedinIcon" onChange={(e) => handleAlertDialogOpen('footerLinkedinIcon')} hidden />
              </Button>
              <img src={tempImages.footerLinkedinIcon} alt="Linkedin icon" width={32} height={32}/>
            </Box>
            </Box>
            </Stack>
            {/* <Button variant="contained" sx={{my:5, mx: 1}} component="label" onClick={updateFooter}>
              Save
            </Button> */}
          </TabPanel>
          {alertDialogOpen && (
                    <AlertDialog
                      content={content}
                      handleClose={handleAlertDialogClose}
                      userId={selectedImageElId}
                      isDialogueOpen={alertDialogOpen}
                    />
                  )}
        </Card>
        <Button variant="contained" sx={{my:5, mx: 1}} component="label" onClick={updateSocials}>
              Save
            </Button>
      </Container>
    </Page>
  );
}
