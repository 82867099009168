import { Navigate } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import User from './pages/User/User';
import CreateUser from './pages/User/CreateUser';
import Login from './pages/Auth/Login';
import NotFound from './pages/Page404';
import Forget from './pages/Auth/Forget';
import Articles from './pages/Article/Articles';
import CreateArticle from './pages/Article/CreateArticle';
import ArticleEdit from './pages/Article/ArticleEdit';
import { ArticleView } from './pages/Article/ArticleView';
import Subscriptions from './pages/Subscription/Subscriptions';
import DashboardApp from './pages/DashboardApp';
import NewPassword from './pages/Auth/NewPassword';
import UserEdit from './pages/User/UserEdit';
import CreateSubscription from './pages/Subscription/CreateSubscription';
import ImagesList from './pages/Images/ImagesList';
import Categories from './pages/Category/Categories';
import Hashtags from './pages/Hashtag/Hashtags';
import CreateCategory from './pages/Category/CreateCategory';
import CreateHashtag from './pages/Hashtag/CreateHashtag';
import CategoryEdit from './pages/Category/CategoryEdit';
import HashtagEdit from './pages/Hashtag/HashtagEdit';
import { UserView } from './pages/User/UserView';
import UserSubscriptionGift from './pages/User/UserSubscriptionGift';
import { isLoggedIn } from './api';
import SubscriptionEdit from './pages/Subscription/SubscriptionEdit';
import Options from './pages/Options/Options';

// ----------------------------------------------------------------------

export const router = () => [
    {
      path: '/dashboard',
      element: isLoggedIn() ? <DashboardLayout /> : <Navigate to='/login' />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'articles', element: <Articles /> },
        { path: 'articles/view/:id', element: <ArticleView /> },
        { path: 'articles/add', element: <CreateArticle /> },
        { path: 'articles/edit/:id', element: <ArticleEdit /> },
        { path: 'blog', element: <ImagesList /> },
        { path: 'options', element: <Options/> },
        { path: 'subscriptions', element: <Subscriptions /> },
        { path: 'subscriptions/add', element: <CreateSubscription /> },
        { path: 'subscriptions/edit/:id', element: <SubscriptionEdit />},
        { path: 'user/add', element: <CreateUser /> },
        { path: 'user/edit/:id', element: <UserEdit /> },
        { path: 'user/view/:id', element: <UserView /> },
        { path: 'user/gift/:id', element: <UserSubscriptionGift />},
        { path: 'categories', element: <Categories /> },
        { path: 'categories/add', element: <CreateCategory /> },
        { path: 'categories/edit/:id', element: <CategoryEdit /> },
        { path: 'hashtags', element: <Hashtags /> },
        { path: 'hashtags/add', element: <CreateHashtag /> },
        { path: 'hashtags/edit/:id', element: <HashtagEdit /> },
      ],
    },
    {
      path: '/',
      element: !isLoggedIn() ? <LogoOnlyLayout /> : <Navigate to='/dashboard/app' />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },
        { path: 'forget', element: <Forget /> },
        { path: 'new-password/:token', element: <NewPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];
