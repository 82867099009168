import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// material
import { Stack } from '@mui/material';
import { Interweave } from 'interweave';
// api
import { getById } from '../../api/Article';
import { LoadingContext } from '../../hoc/loading';
import { MessageContext } from '../../hoc/messageNotification';


export const ArticleView = () => {
  const [article, setArticle] = useState({});
  const {setLoading} = useContext(LoadingContext);
  const {setErrorMessage} = useContext(MessageContext);

  const { id } = useParams();


  const getArticleById = async () => {
    try {
      setLoading(true)
      const res = await getById(id)
      setArticle(res);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  useEffect(() => {
      getArticleById()
  },[])

    return (
        <>
        <Stack spacing={3}>
          <h1>
            View Article
          </h1>
        </Stack>
        <Stack style={{textAlign:"center"}}>
        <h1>{article.title}</h1>
        <div style={{color: '#666'}}>{article.description}</div>
        <div style={{margin: '0 auto'}}>
        {article.image ? <img src={article.image.url} alt="Article" width={400} height={300}/> : ''}
        </div>
        </Stack>
        <Stack spacing={3} my={3} mx={3}>
        <Interweave content={article.article} />
        </Stack>
        </>
    )
}