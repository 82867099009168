import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import { create as uploadImage } from '../../api/Image';
import { getAll as getCategory } from '../../api/Category';
import { LoadingContext } from '../../hoc/loading';
import { MessageContext } from '../../hoc/messageNotification';
import { getById, update } from '../../api/Subscription';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function SubscriptionEdit() {
  const navigate = useNavigate();

  const [subscription, setSubscription] = useState();
  const [active, setActive] = useState(false);
  const [categories, setCategories] = useState([]);
  const [viewAccess, setViewAccess] = useState();
  const [viewAccessList, setViewAccessList] = useState();
  const [categoriesOptions, setCategoriesOptions] = useState([]);

  const { id } = useParams();

  const { setLoading } = useContext(LoadingContext);
  const { setErrorMessage } = useContext(MessageContext);

  const getSubscriptionById = async (id) => {
    setLoading(true);
    try {
      const res = await getById(id);
      setCategories(res.categories);
      setValues(res);
      setSubscription(res);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const handleSelectChange = (e, type) => {
    const input = new FormData();
    const file = document.getElementById('file-input').files[0];
    switch (type) {
      case 'gender':
        values.gender = e.target.value;
        break;
      case 'role':
        values.role = e.target.value;
        break;
      case 'image':
        input.append('file', file);
        input.append('name', file.name)
        setLoading(true);
        uploadImage(input)
          .then((res) => {
            values.image = res.url;
          })
          .catch((e) => setErrorMessage(e));
        setLoading(false);
        break;
      default:
        break;
    }
  };

  const getCategories = async () => {
    try {
      setLoading(true);
      const response = await getCategory();
      setCategoriesOptions(response);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCategories();
    getSubscriptionById(id);
  }, []);

  const formik = useFormik({
    initialValues: {
      title: '',
      subtitle: '',
      active: false,
      categories: [],
      prices: [],
      image: '',
      subscription: {},
    },
    onSubmit: () => {
      onEditSubscription(values);
      navigate('/dashboard/subscriptions');
    },
  });

  const onCategoriesInputChange = (v) => {
    setCategories(v);
    values.categories = v;
  };

  const onEditSubscription = async (values) => {
    setLoading(true);
    try {
      await update(values);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setValues } = formik;

  const inputs = [{ id: 'title', label: 'Title' }];

  return (
    <FormikProvider value={formik}>
      <Stack spacing={3}>
        <h1>Edit subscription plan</h1>
      </Stack>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Button variant="contained" sx={{ my: 3 }} component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
              Optional, if you want to change the image
              <input type="file" id="file-input" onChange={(e) => handleSelectChange(e, 'image')} hidden />
            </Button>
        <Stack spacing={3}>
          {inputs.map((e) => (
            <TextField
              fullWidth
              key={e.id}
              autoComplete={e.id}
              type="text"
              placeholder={e.label}
              {...getFieldProps(e.id)}
              error={Boolean(touched[e.id] && errors[e.id])}
              helperText={touched[e.id] && errors[e.id]}
            />
          ))}
          <Stack my={3}>
            {subscription ? (
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  id="hashtags"
                  options={categoriesOptions}
                  value={categories}
                  disableCloseOnSelect
                  onChange={(e, v) => onCategoriesInputChange(v)}
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option.title}
                    </li>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => <TextField {...params} placeholder="Categories" />}
                />
              </FormControl>
            ) : (
              ''
            )}
          </Stack>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Edit
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
