// routes
import { useRoutes } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import { router } from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { Hoc } from './hoc';

// ----------------------------------------------------------------------

export const CustomLabelTextField = withStyles({
  root: {
    marginRight: 8,
    "& .MuiInputBase-root": {
      margin: "10px 5px",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      margin: "10px 5px",
      color: "rgb(0, 0, 0)"
    }
  }
})(TextField);

export default function App() {
  const routing = useRoutes(router());

  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Hoc>{routing}</Hoc>
    </ThemeProvider>
  );
}
