// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'users management',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'articles',
    path: '/dashboard/articles',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'Gallery',
    path: '/dashboard/blog',
    icon: getIcon('eva:shopping-bag-fill'),
  },
  {
    title: 'Hashtags',
    path: '/dashboard/hashtags',
    icon: getIcon('mdi:format-header-pound'),
  },
  {
    title: 'Categories',
    path: '/dashboard/categories',
    icon: getIcon('mdi:shape'),
  },
  {
    title: 'subscriptions',
    path: '/dashboard/subscriptions',
    icon: getIcon('eva:checkmark-square-2-outline'),
  },
  {
    title: 'options',
    path: '/dashboard/options',
    icon: getIcon('eva:settings-2-outline'),
  }
];

export default navConfig;
