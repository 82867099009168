import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, Checkbox, TextField, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import { create } from '../../api/Hashtag';
import { MessageContext } from '../../hoc/messageNotification';
import { LoadingContext } from '../../hoc/loading';

// ----------------------------------------------------------------------

export default function CreateHashtag() {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);

  const {setLoading} = useContext(LoadingContext);
  const {setErrorMessage} = useContext(MessageContext);

  const HashtagSchema = Yup.object().shape({
    title: Yup.string().required('Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      active: false
    },
    validationSchema: HashtagSchema,
    onSubmit: () => createHashtag(),
  });

  const createHashtag = async () => {
    try {
      setLoading(true)
      await create(values)
      navigate('/dashboard/hashtags', { replace: true });
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false)
  }

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleSelectChange = (e, type) => {
    values.active = e.target.checked;
    setActive(e.target.checked);
  }

  const inputs = [
    {id: "title", label: "Title"},
  ]

  return (
    <FormikProvider value={formik}>
        <Stack spacing={3} sx={{marginBottom: 3}}>
          <h1>
            Create hashtag
          </h1>
        </Stack>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {inputs.map(e => (
            <TextField
            fullWidth
            key={e.id}
            autoComplete={e.id}
            type="text"
            label={e.label}
            {...getFieldProps(e.id)}
            error={Boolean(touched[e.id] && errors[e.id])}
            helperText={touched[e.id] && errors[e.id]}
          />
          ))}

            <FormControlLabel 
            label="Active"
            control={
              <Checkbox
              checked={active}
              onChange={(e) => handleSelectChange(e, "active")}
              />
            }/>

        </Stack>

        <LoadingButton sx={{marginTop: 3}} fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Create
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}