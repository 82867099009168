import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Autocomplete,
  Checkbox,
  Button,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import RichTextEditor from 'react-rte';
import Editor from '../../components/ArticleEditor';
// api
import { getAll as getHashtag } from '../../api/Hashtag';
import { getAll as getCategory } from '../../api/Category';
import { create as uploadImage } from '../../api/Image';
import { getById, update } from '../../api/Article';
import { LoadingContext } from '../../hoc/loading';
import { MessageContext } from '../../hoc/messageNotification';
import { Modal } from '../../components/Modal';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function EditArticle() {
  const navigate = useNavigate();
  const [category, setCategory] = useState('');
  const [state, setState] = useState('');
  const [article, setArticle] = useState({});
  const [openImgAddModal, setOpenImgAddModal] = useState();
  const [articleBody, setArticleBody] = useState(RichTextEditor.createValueFromString('<p></p>', 'html'));
  const [tagsOptions, setTagsOptions] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);

  const { setLoading } = useContext(LoadingContext);
  const { setErrorMessage } = useContext(MessageContext);

  const { id } = useParams();

  const getArticleById = async (id) => {
    try {
      setLoading(true);
      const res = await getById(id);
      setArticle(res);
      setValues(res);
      setHashtags(res.hashtags);
      setCategory(res.category);
      setState(res.state);
      setArticleBody(RichTextEditor.createValueFromString(res.article, 'html'));
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getHashtags();
    getCategories();
    getArticleById(id);
  }, []);

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      category: '',
      article: '',
      state: '',
      author: '',
      hashtags: [],
    },
    onSubmit: async () => {
      await editArticle();
      navigate('/dashboard/app/articles', { replace: true });
    },
  });

  const onArticleInputChange = (value) => {
    values.article = value;
    setArticleBody(value);
  };

  const onAddImageToArticleInput = (value, image) => {
    const pClose = '</p>';
    values.article = value.toString('html').replace('</p>', image + pClose);
    setArticleBody(RichTextEditor.createValueFromString(values.article, 'html'));
  };

  const handleImageSelect = (url) => {
    onAddImageToArticleInput(articleBody, `<img src="${url}"/>`);
  };

  const openImgModal = () => {
    setOpenImgAddModal(true);
  };

  const closeImgModal = () => {
    setOpenImgAddModal(false);
  };

  const getHashtags = async () => {
    try {
      setLoading(true);
      const response = await getHashtag();
      setTagsOptions(response);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const getCategories = async () => {
    try {
      setLoading(true);
      const response = await getCategory();
      setCategoriesOptions(response);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const editArticle = async () => {
    try {
      setLoading(true);
      await update({...values, article: values.article.toString('html')});
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const onHashtagsInputChange = (v) => {
    setHashtags(v);
    values.hashtags = v;
  };

  const handleSelectChange = (e, type) => {
    const input = new FormData();
    const file = document.getElementById('file-input').files[0];
    switch (type) {
      case 'category':
        values.category = e;
        setCategory(e);
        break;
      case 'state':
        values.state = e.target.value;
        setState(e.target.value);
        break;
      case 'image':
        input.append('file', file);
        setLoading(true);
        uploadImage(input)
          .then((res) => {
            values.image = res;
          })
          .catch((e) => setErrorMessage(e));
        setLoading(false);
        break;
      default:
        break;
    }
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setValues } = formik;

  const inputs = [
    { id: 'title', label: 'Title' },
    { id: 'description', label: 'Description' },
    { id: 'author', label: 'Author' },
  ];

  return (
    <FormikProvider value={formik}>
      <Stack spacing={3}>
        <h1>Edit Article</h1>
      </Stack>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Button variant="contained" sx={{ my: 3 }} component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
            {"Main image, optional if you want to change"}
            <input type="file" id="file-input" onChange={(e) => handleSelectChange(e, 'image')} hidden />
          </Button>
        <Stack spacing={3}>
          {inputs.map((e) => (
            <TextField
              key={e.id}
              fullWidth
              autoComplete={e.id}
              type="text"
              placeholder={e.label}
              {...getFieldProps(e.id)}
              error={Boolean(touched[e.id] && errors[e.id])}
              helperText={touched[e.id] && errors[e.id]}
            />
          ))}
          <Stack my={3}>
            {article ? (
              <FormControl fullWidth>
                <Autocomplete
                  id="categories"
                  options={categoriesOptions}
                  value={category}
                  disableCloseOnSelect
                  onChange={(e, v) => handleSelectChange(v, 'category')}
                  getOptionLabel={(option) => option.title || ''}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option.title}
                    </li>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => <TextField {...params} placeholder="Category" />}
                />
              </FormControl>
            ) : (
              ''
            )}
          </Stack>
          <Stack my={3}>
            {article ? (
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  id="hashtags"
                  options={tagsOptions}
                  value={hashtags}
                  disableCloseOnSelect
                  onChange={(e, v) => onHashtagsInputChange(v)}
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option.title}
                    </li>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => <TextField {...params} placeholder="Tags" />}
                />
              </FormControl>
            ) : (
              ''
            )}
          </Stack>
          
          <FormControl fullWidth>
            <InputLabel id={`select-state`}>Status of accessability</InputLabel>
            <Select
              labelId={`select-state`}
              label="Status of accessability"
              value={state}
              onChange={(e) => handleSelectChange(e, 'state')}
            >
              <MenuItem value="DRAFT">Draft</MenuItem>
              <MenuItem value="PRIVATE">Private</MenuItem>
              <MenuItem value="PUBLIC">Public</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <Typography sx={{fontSize: "23px", fontWeight: 600, margin: "15px 0"}}>Add image to the article body</Typography>
        <Typography sx={{fontSize: "18px", fontWeight: 500}}>Please, upload the images 1140x640</Typography>
        <Button sx={{my: 2}} variant="outlined" onClick={openImgModal}>
          Pin Image To Article
        </Button>
        <Stack my={3}>
          <Editor value={articleBody} onArticleInputChange={onArticleInputChange} />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            placeholder="Remember me"
          /> */}
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Update
        </LoadingButton>
      </Form>
      {openImgAddModal && (
        <Modal
          isOpen={openImgAddModal}
          size={'fullscreen'}
          title={'Add Image'}
          onClose={closeImgModal}
          handleImageSelect={handleImageSelect}
          name="AddImage"
        />
      )}
    </FormikProvider>
  );
}
