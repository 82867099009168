import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Iconify from './Iconify';
import Templates from './ModalTemplates';
import './styles/modal-styles.css';

export const Modal = ({ name, title, size = 'default', isOpen, onClose, ...rest }) => {
  const componentObject = Templates.filter((componentArray) => componentArray.name === name)[0];
  const handleClose = (event, data = true) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    onClose(data);
  };

  const sizeList = `modal modal-dialog`;

  return (
    <div className={'modal__background'} style={{ display: isOpen ? 'flex' : 'none' }}>
      <Box className={'modal-dialog'}>
        <Box className={sizeList} onClick={(event) => event.stopPropagation(event)}>
          <div className="modal__header">
            <Iconify
              className="modal__icon-close"
              onClick={(event) => handleClose(event)}
              icon={'eva:close-fill'}
              sx={{ width: 32, height: 32, ml: 1 }}
            />
            <h2 className="modal__title">{title}</h2>
          </div>
          <div className="modal__content">
            <componentObject.component handleClose={handleClose} {...rest} />
          </div>
        </Box>
      </Box>
    </div>
  );
};
