import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { useNavigate } from 'react-router-dom';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import { post } from '../api'

export const CreatePrice = ({subscription}) => {

    const [monthlyAmount, setMonthlyAmount] = useState();
    const [annualyAmount, setAnnualyAmount] = useState();
    const navigate = useNavigate()
  
    const PriceSchema = Yup.object().shape({
      title: Yup.string().required('Title is required'),
      subtitle: Yup.string().required('Subtitle is required')
    });

    useEffect(() => {console.log('changed', subscription)}, [subscription])
  
    const formik = useFormik({
      initialValues: {
        title: 'Price',
        subtitle: 'Price subtitle',
        active: true,
        amount: '',
        currency: 'EUR',
        period: 'MONTH',
        subscriptionType: subscription
      },
      validationSchema: PriceSchema,
      onSubmit: async () => {
        await createPrice(values)
        handleSubmit1();
      },
    });

    const formik1 = useFormik({
        initialValues: {
          title: 'Price',
          subtitle: 'Price subtitle',
          active: true,
          amount: '',
          currency: 'EUR',
          period: 'YEAR',
          subscriptionType: subscription
        },
        validationSchema: PriceSchema,
        onSubmit: async () => {
          await createPrice(values1)
          navigate('/dashboard/subscriptions')
        },
      });

    const createPrice = (values) => {
      const res = post('api/price/create', values).then(res => res.data)
      return res;
    };
  
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const { errors: errors1, touched: touched1, values: values1, isSubmitting: isSubmitting1, handleSubmit: handleSubmit1, getFieldProps: getFieldProps1 } = formik1;
  
    const handleTextInputChange = (e, type) => {
      switch(type) {
        case "monthly":
          values.amount = e.target.value;
          setMonthlyAmount(e.target.value);
          break;
          case "annualy":
          values1.amount = e.target.value;
          setAnnualyAmount(e.target.value);
          break;
        default: break;
      }
    }
  
    return (
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Stack spacing={3}>
              <TextField
              fullWidth
              autoComplete={'amount'}
              type="number"
              label={'Monthly amount'}
              value={monthlyAmount}
              onChange={(e) => handleTextInputChange(e, 'monthly')}
              {...getFieldProps('amount')}
              error={Boolean(touched.amount && errors.amount)}
              helperText={touched.amount && errors.amount}
            />
          </Stack>

          <Stack spacing={3} marginY={3}>
              <TextField
              fullWidth
              autoComplete={'amount'}
              type="number"
              value={annualyAmount}
              label={'Annualy amount'}
              onChange={(e) => handleTextInputChange(e, 'annualy')}
              {...getFieldProps1('amount')}
              error={Boolean(touched1.amount && errors1.amount)}
              helperText={touched1.amount && errors1.amount}
            />
          </Stack>
  
          {<LoadingButton  direction="row" size="large" type="submit" variant="contained" onClick={handleSubmit} loading={isSubmitting || isSubmitting1}>
            Create Prices
          </LoadingButton>}
        </Form>
      </FormikProvider>
    )
  }