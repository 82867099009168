import { useContext, useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  TableContainer,
  Table,
  Card,
  TableBody,
  TableRow,
  TableCell,
  Stack,
  Box,
} from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppCurrentVisits,
  AppWebsiteVisits,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { getAll } from '../api/User';
import { LoadingContext } from '../hoc/loading';
import { MessageContext } from '../hoc/messageNotification';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../sections/@dashboard/user';
import { getTransactionReportReq } from '../api/Statistics';

const TABLE_HEAD = [
  { id: 'email', label: 'E-mail', alignRight: false },
  { id: 'lastLogin', label: 'Last Login', alignRight: true },
];

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [users, setUsers] = useState([]);
  const [transactionReport, setTransactionReport] = useState();

  const theme = useTheme();
  const { setLoading } = useContext(LoadingContext);
  const { setErrorMessage } = useContext(MessageContext);

  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await getAll();
      const resultSorted = res.sort((a, b) => {
        return new Date(b.lastLogin) - new Date(a.lastLogin);
      });
      console.log('RES', resultSorted);
      setUsers(res);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const getTransactionReport = async () => {
    // new Date(2010, 6, 26).getTime() / 1000
    try {
      setLoading(true);
      const res = await getTransactionReportReq({from: new Date(2022, 6, 1).getTime() / 1000, until: new Date(2022, 7, 31).getTime() / 1000});
      
      setTransactionReport(res);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  } 

  useEffect(() => {
    getUsers();
    getTransactionReport();
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            {/* <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            /> */}
            <Card>
            <Typography variant="h3" sx={{ mx: 3, my: 3 }}>
                Total transactions
            </Typography>
            <Box sx={
              {
                height: "25vh",
                mx: 3, my: 3 
              }
            }>
              <Typography gutterBottom variant="subtitle1">
                Total amount of transactions
            </Typography>
            <Typography variant="h4" sx={{ mx: 2, my: 2 }}>
                {transactionReport?.charge.split('/')[0]} EUR
            </Typography>
            <Typography gutterBottom variant="subtitle1">
                Quantity of transactions
            </Typography>
            <Typography variant="h4" sx={{ mx: 2, my: 2 }}>
                {transactionReport?.charge.split('/')[1]}
            </Typography>
            </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Scrollbar>
              <Card>
              <TableContainer sx={{ minWidth: 200 }}>
                  <Table>
                    <UserListHead order={'asc'} orderBy={'lastLogin'} headLabel={TABLE_HEAD} rowCount={users.length} />
                    <TableBody>
                      {users.slice(0, 10).map((row) => {
                        const { id, email, lastLogin } = row;

                        return (
                          <TableRow hover key={id} tabIndex={-1}>
                            <TableCell component="th" scope="row">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {email}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="right">{new Date(lastLogin).toISOString().split('.')[0].replace('T', " ").slice(0, -3)}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
              </TableContainer>
                </Card>
            </Scrollbar>
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.chart.blue[0],
                theme.palette.chart.violet[0],
                theme.palette.chart.yellow[0],
              ]}
            />
          </Grid>

        </Grid>
      </Container>
    </Page>
  );
}
