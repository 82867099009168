import { filter } from 'lodash';
import { useState, useEffect, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,   
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import { AlertDialog } from '../../components/AlertDialog';
import { getAll, remove, update, create } from '../../api/Image';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead as PostListHead, UserListToolbar as PostListToolbar, UserMoreMenu as PostMoreMenu } from '../../sections/@dashboard/user';
import { MessageContext } from '../../hoc/messageNotification';
import { LoadingContext } from '../../hoc/loading';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false},
  { id: 'image', label: '', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false},
  { id: 'created', label: 'Date of creation', alignRight: false}
  
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_post) => _post.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Posts() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('created');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [posts, setPosts] = useState([]);
  const [selectedPostId, setSelectedPostId] = useState();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const {setLoading} = useContext(LoadingContext);
  const { setErrorMessage, setSuccessMessage } = useContext(MessageContext);

  const getPosts = async () => {
    try {
      setLoading(true)
      const res = await getAll()
      setPosts(res)
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = posts.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleAlertDialogOpen = (id) => {
    setSelectedPostId(id)
    setOpenDeletePopup(true)
  }

  const handleAlertDialogClose = () => {
    setOpenDeletePopup(false)
  }

  const handlePostDelete = async (id) => {
    try {
      setLoading(true)
      await remove(id)
      getPosts()
      handleAlertDialogClose()
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false)
  }

  const handleSelectChange = async (e) => {
    const input = new FormData();
    const file = document.getElementById('file-input').files[0];
        input.append('file', file);
        input.append('name', file.name)
        try {
          setLoading(true)
          await create(input)
          setSuccessMessage({"Success": ''})
        } catch (e) {
          setErrorMessage(e)
        }
        setLoading(false)
        getPosts()
  };

  const onItemDeactivate = async (obj) => {
    try {
      setLoading(true)
      await update({...obj, active: false})
      getPosts()
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false)
  }

  const onItemActivate = async (obj) => {
    try {
      setLoading(true)
      await update({...obj, active: true})
      getPosts()
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  useEffect(() => {
    getPosts()
  }, [])

  const content = {
    title: 'Are you sure to delete this image?',
    description: '',
    actions: {"Disagree": handleAlertDialogClose,
            "Agree": (id) => handlePostDelete(id)}
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - posts.length) : 0;

  const filteredPosts = applySortFilter(posts, getComparator(order, orderBy), filterName);

  const isPostNotFound = filteredPosts.length === 0;

  return (
    <Page title="Post">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Gallery
          </Typography>
          <Button variant="contained" component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Image
            <input type="file" id="file-input" onChange={handleSelectChange} hidden/>
          </Button>
        </Stack>

        <Card>
          <PostListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <PostListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={posts.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredPosts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, title, created, name } = row;
                    const image = row.url
                    const dateOfCreation = new Date(created).toISOString().split('.')[0]
                    const isItemSelected = selected.indexOf(title) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell>
                          {id}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              <img src={image} width={70} height={50} alt="imag2" />
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{name || 'Unnamed'}</TableCell>
                        <TableCell align="left">{new Date(created).toISOString().split('.')[0].replace('T', " ").slice(0, -3)}</TableCell>

                        <TableCell align="right">
                          <PostMoreMenu obj={row} handleOpen={handleAlertDialogOpen} onItemActivate={onItemActivate} onItemDeactivate={onItemDeactivate} image={false} article />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {openDeletePopup && <AlertDialog content={content} userId={selectedPostId} handleClose={handleAlertDialogClose} isDialogueOpen={openDeletePopup} />}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isPostNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={posts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}