import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, Checkbox, FormControl, Select, MenuItem, InputLabel, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../components/Iconify';
// api
import { create } from '../../api/User'
import { MessageContext } from '../../hoc/messageNotification';
import { LoadingContext } from '../../hoc/loading';

// ----------------------------------------------------------------------

export default function CreateUser() {
  const navigate = useNavigate();
  const {setErrorMessage} = useContext(MessageContext)
  const {setLoading} = useContext(LoadingContext);
  const [role, setRole] = useState('');
  const [gender, setGender] = useState('');
  
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [active, setActive] = useState(false);

  const UserSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    fullName: Yup.string().required('Full name is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
    role: Yup.string().required('Role is required'),
    gender: Yup.string().required('Identity is required'),
    confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match').required('Confirmation of password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      fullName: '',
      username: '',
      password: '',
      role: '',
      confirmPassword: '',
      gender: ''
    },
    validationSchema: UserSchema,
    onSubmit: async () => {await createUser(); navigate('/dashboard/user', { replace: true })}
  });

const createUser = async () => {
  try {
    setLoading(true)
    await create(values)
  } 
  catch(e) {
    setErrorMessage(e)
  }
  setLoading(false)
}

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleSelectChange = (e, type) => {
    switch(type) {
      case "gender":
        values.gender = e.target.value;
        setGender(e.target.value)
        break;
      case "role":
        values.role = e.target.value;
        setRole(e.target.value)
        break;
      default: break;
    }
  }
  
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowPasswordConfirm = () => {
    setShowPasswordConfirm((show) => !show);
  };

  

  const inputs = [
    {id: "fullName", label: "Full Name"},
    {id: "email", label: "Email"},
    {id: "username", label: "Username"},
    // {id: "address", label: "Address"}
  ]

  return (
    <FormikProvider value={formik}>
        <Stack spacing={1} sx={{ mb: 2 }}>
          <h1>
            Create user
          </h1>
        </Stack>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} my={3}>
          {inputs.map(e => (
            <TextField
            fullWidth
            key={e.id}
            autoComplete={e.id}
            type="text"
            placeholder={e.label}
            {...getFieldProps(e.id)}
            error={Boolean(touched[e.id] && errors[e.id])}
            helperText={touched[e.id] && errors[e.id]}
          />
          ))}

            <FormControl fullWidth>
                <InputLabel id={`select-role`}>Role</InputLabel>
                <Select
                labelId={`select-role`}
                label={"Role"}
                value={role}
                onChange={(e) => handleSelectChange(e, "role")}
                error={Boolean(touched.role && errors.role)}
                helperText={touched.role && errors.role}
                >
                    <MenuItem value={"SUPER_ADMIN"}>Super Admin</MenuItem>
                    <MenuItem value={"ADMIN"}>Admin</MenuItem>
                    <MenuItem value={"FREE_USER"}>Free User</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth>
                <InputLabel id={`select-gender`}>Identity</InputLabel>
                <Select
                labelId={`select-gender`}
                label={"Identity"}
                value={gender}
                onChange={(e) => handleSelectChange(e, "gender")}
                error={Boolean(touched.gender && errors.gender)}
                helperText={touched.gender && errors.gender}
                >
                    <MenuItem value={"MALE"}>Mr</MenuItem>
                    <MenuItem value={"FEMALE"}>Mrs</MenuItem>
                </Select>
            </FormControl>

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPasswordConfirm ? 'text' : 'password'}
            placeholder="Confirm Password"
            {...getFieldProps('confirmPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPasswordConfirm} edge="end">
                    <Iconify icon={showPasswordConfirm ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Create
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}