import { post, get, put, deletion } from "./index"

  export const getByKeyOrId = (key) => {
    return new Promise((resolve, rej) => {
        get(`api/settings/${key}`)
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }

  export const getAll = () => {
    return new Promise((resolve, rej) => {
        get('api/settings/list')
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }

  export const updateOne = (data) => {
    return new Promise((resolve, rej) => {
        put('api/settings/update', data)
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }

  export const updateList = (list) => {
    return new Promise((resolve, rej) => {
        put('api/settings/update_all', list)
        .then(res => resolve(res.data))
        .catch(e => {rej(e.response.data.message)}) 
    }) 
  }