import React, { useState } from "react"

import { makeStyles } from "@mui/styles"
import { CircularProgress, Grid } from "@mui/material"

export const LoadingContext = React.createContext(false)

const useStyles = makeStyles(theme => ({
  loading: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}))

export const LoadingHOC = ({ children }) => {
  const classes = useStyles()
  const [loadingContextData, setLoadingContextData] = useState(false)

  return (
    <LoadingContext.Provider
      value={{ loading: loadingContextData, setLoading: setLoadingContextData }}
    >
      {children}
      {loadingContextData && (
        <Grid className={classes.loading} container justifyContent={"center"}>
          <CircularProgress />
        </Grid>
      )}
    </LoadingContext.Provider>
  )
}
