import { useState, useEffect, useContext } from 'react';
import RichTextEditor from 'react-rte';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Autocomplete,
  Checkbox,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LoadingButton } from '@mui/lab';
import Editor from '../../components/ArticleEditor';
import { Modal } from '../../components/Modal';

// api
import { create } from '../../api/Article';
import { getAll as getHashtag } from '../../api/Hashtag';
import { getAll as getCategory } from '../../api/Category';
import { create as uploadImage } from '../../api/Image';
import { MessageContext } from '../../hoc/messageNotification';
import { LoadingContext } from '../../hoc/loading';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function CreateArticle() {
  const navigate = useNavigate();

  const [category, setCategory] = useState('');
  const [state, setState] = useState('');
  const [dateOfCreation, setDateOfCreation] = useState(new Date());
  const [hashtags, setHashtags] = useState([]);
  const [openImgAddModal, setOpenImgAddModal] = useState();
  const [categoriesList, setCategoriesList] = useState([]);
  const [articleBody, setArticleBody] = useState(RichTextEditor.createValueFromString('<p></p>', 'html'));
  const [tagsOptions, setTagsOptions] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [mainImage, setMainImage] = useState();
  const [categoriesOptions, setCategoriesOptions] = useState([]);

  const { setLoading } = useContext(LoadingContext);
  const { errorMessage, setErrorMessage } = useContext(MessageContext);

  const getCategories = async () => {
    try {
      setLoading(true);
      const response = await getCategory();
      setCategoriesList(response);
      setCategoriesOptions(response);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const getHashtags = async () => {
    try {
      setLoading(true);
      const response = await getHashtag('api/hashtag/list');
      setTagsList(response);
      setTagsOptions(response);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCategories();
    getHashtags();
  }, []);

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      article: '',
      state: '',
      image: {},
      author: '',
      hashtags: [],
      published: '',
    },
    onSubmit: async () => {
      await createArticle();
      navigate('/dashboard/articles', { replace: true });
    },
  });

  const handleSelectChange = (e, type) => {
    const input = new FormData();
    const file = document.getElementById('file-input').files[0];
    switch (type) {
      case 'state':
        values.state = e.target.value;
        setState(e.target.value);
        break;
      case 'image':
        input.append('file', file);
        input.append('name', file.name)
        setLoading(true);
        uploadImage(input)
          .then((res) => {
            values.image = res;
            setMainImage(res)
          })
          .catch((e) => setErrorMessage(e));
        setLoading(false);
        break;
      default:
        break;
    }
  };

  const onHashtagsInputChange = (v) => {
    setHashtags(v);
    values.hashtags = v;
  };

  const onDateInputChange = (e) => {
    const d = new Date(e).toISOString().split('.')[0];
    values.published = d;
    setDateOfCreation(e);
  };

  const onCategoriesInputChange = (v) => {
    values.category = v;
  };

  const onAddImageToArticleInput = (value, image) => {
    const pClose = '</p>';
    values.article = value.toString('html').replace('</p>', image + pClose);
    setArticleBody(RichTextEditor.createValueFromString(values.article, 'html'));
  };

  const onArticleInputChange = (value) => {
    const valueToString = value.toString('html')
    values.article = valueToString
    setArticleBody(value);
  };

  const openImgModal = () => {
    setOpenImgAddModal(true);
  };

  const closeImgModal = () => {
    setOpenImgAddModal(false);
  };

  const handleImageSelect = (url) => {
    onAddImageToArticleInput(articleBody, `<img src="${url}"/>`);
  };

  const createArticle = async () => {
    try {
      setLoading(true);
      await create(values)
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const inputs = [
    { id: 'title', label: 'Title' },
    { id: 'description', label: 'Description' },
    { id: 'author', label: 'Author' },
  ];

  return (
    <>
      <FormikProvider value={formik}>
        <Stack spacing={3}>
          <h1>Create article</h1>
        </Stack>
        
          <Button variant="contained" sx={{my:3}} component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
            Main image of the article
            <input type="file" id="file-input" onChange={(e) => handleSelectChange(e, 'image')} hidden />
          </Button>

          {mainImage && <img src={mainImage.url} width={1140} height={640} style={{marginBottom: "20px"}} alt={mainImage}/>}

        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {inputs.map((e) => (
              <TextField
                fullWidth
                autoComplete={e.id}
                type="text"
                placeholder={e.label}
                {...getFieldProps(e.id)}
                error={Boolean(touched[e.id] && errors[e.id])}
                helperText={touched[e.id] && errors[e.id]}
              />
            ))}
            <Stack my={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  placeholder="Date of creation"
                  value={dateOfCreation}
                  onChange={(newValue) => {
                    onDateInputChange(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Stack>

            <Stack my={3}>
              <FormControl fullWidth>
                <Autocomplete
                  id="categories"
                  options={categoriesOptions}
                  disableCloseOnSelect
                  onChange={(e, v) => onCategoriesInputChange(v)}
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option.title}
                    </li>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => <TextField {...params} placeholder="Category" />}
                />
              </FormControl>
            </Stack>
            <FormControl fullWidth>
              <InputLabel id={`select-state`}>Status of accessability</InputLabel>
              <Select
                labelId={`select-state`}
                label="Status of accessability"
                value={state}
                onChange={(e) => handleSelectChange(e, 'state')}
              >
                <MenuItem value="DRAFT">Draft</MenuItem>
                <MenuItem value="PRIVATE">Private</MenuItem>
                <MenuItem value="PUBLIC">Public</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          <Stack my={3}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                id="hashtags"
                options={tagsOptions}
                disableCloseOnSelect
                onChange={(e, v) => onHashtagsInputChange(v)}
                getOptionLabel={(option) => option.title}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option.title}
                  </li>
                )}
                style={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} placeholder="Tags" />}
              />
            </FormControl>
          </Stack>
          
          <Typography sx={{fontSize: "23px", fontWeight: 600, margin: "15px 0"}}>Add image to the article body</Typography>
          <Typography sx={{fontSize: "18px", fontWeight: 500, marginBottom: "10px"}}>Please, upload the images 1140x640</Typography>
          <Button variant="outlined" onClick={openImgModal}>
            Pin Image To Article
          </Button>

          <Stack my={3}>
            <Editor value={articleBody} onArticleInputChange={onArticleInputChange} />
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Create
          </LoadingButton>
        </Form>
      </FormikProvider>
      {openImgAddModal && (
        <Modal
          isOpen={openImgAddModal}
          size={'fullscreen'}
          title={'Add Image'}
          onClose={closeImgModal}
          handleImageSelect={handleImageSelect}
          name="AddImage"
        />
      )}
    </>
  );
}
