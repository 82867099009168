import { useState, useEffect, useContext } from 'react';
import { filter } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { AlertDialog } from '../../components/AlertDialog';
import { LoadingContext } from '../../hoc/loading';
// api
import { getAll, remove, update } from '../../api/Article';
import { MessageContext } from '../../hoc/messageNotification';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'created', label: 'Created', alignRight: false },
  { id: 'author', label: 'Author', alignRight: false },
  { id: 'createdBy', label: 'Created By', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_article) => _article.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Articles() {
  const [openFilter, setOpenFilter] = useState(false);
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('created');
  const [filterTitle, setFilterTitle] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [selectedArticleId, setSelectedArticleId] = useState();

  const { setErrorMessage } = useContext(MessageContext);
  const { setLoading } = useContext(LoadingContext);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = articles.map((n) => n.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, title) => {
    const selectedIndex = selected.indexOf(title);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, title);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onItemDeactivate = async (obj) => {
    try {
      setLoading(true);
      await update({ ...obj, active: false });
      getArticleList();
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const onItemActivate = async (obj) => {
    try {
      setLoading(true);
      await update({ ...obj, active: true });
      getArticleList();
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const handleAlertDialogOpen = (id) => {
    setSelectedArticleId(id);
    setAlertDialogOpen(true);
  };

  const handleAlertDialogClose = () => {
    setAlertDialogOpen(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByTitle = (event) => {
    setFilterTitle(event.target.value);
  };

  const content = {
    title: 'Are you sure to delete this article?',
    description: '',
    actions: {
      Disagree: handleAlertDialogClose,
      Agree: (id) => {
        onClickDeleteArticle(id);
        handleAlertDialogClose();
      },
    },
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - articles.length) : 0;

  const filteredArticles = applySortFilter(articles, getComparator(order, orderBy), filterTitle);

  const isUserNotFound = filteredArticles.length === 0;

  const onClickDeleteArticle = async (id) => {
    try {
      setLoading(true);
      await remove(id);
      getArticleList();
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const getArticleList = async () => {
    try {
      setLoading(true);
      const res = await getAll();
      res.sort((a, b) => {
        return new Date(b.create) - new Date(a.created);
      });
      setArticles(res);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getArticleList();
  }, []);

  return (
    <Page title="Articles">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Articles
          </Typography>
          <Button variant="contained" component={RouterLink} to="add" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Article
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterTitle} onFilterName={handleFilterByTitle} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={articles.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredArticles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, title, created, image, author, createdBy } = row;
                    const creatorsEmail = createdBy.email;
                    const isItemSelected = selected.indexOf(title) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell>{id}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {title}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          {new Date(created).toISOString().split('.')[0].replace('T', ' ').slice(0, -3)}
                        </TableCell>
                        <TableCell align="left">{author === null ? 'No author' : author}</TableCell>
                        <TableCell align="left">{creatorsEmail}</TableCell>
                        <TableCell align="left">
                          {image ? <img width={100} height={80} style={{objectFit: "cover", width: "140px", height: "100px"}} src={image.url} alt="Article img" /> : 'No image'}
                        </TableCell>

                        <TableCell align="right">
                          <UserMoreMenu
                            article
                            obj={row}
                            handleOpen={handleAlertDialogOpen}
                            onItemActivate={onItemActivate}
                            onItemDeactivate={onItemDeactivate}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {alertDialogOpen && (
                    <AlertDialog
                      content={content}
                      handleClose={handleAlertDialogClose}
                      userId={selectedArticleId}
                      isDialogueOpen={alertDialogOpen}
                    />
                  )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterTitle} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={articles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
