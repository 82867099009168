import React from "react"
import { LoadingHOC } from "./loading"
import {MessageNotificationHOC} from "./messageNotification"

export const Hoc = ({children}) => {
    return (
        <LoadingHOC>
            <MessageNotificationHOC>{children}</MessageNotificationHOC>
        </LoadingHOC>
    )
}
