import React, { useContext, useEffect, useState } from 'react';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import ImageUploading from 'react-images-uploading';
import './styles/add-image-styles.css';
import Box from '@mui/material/Box';
import { Tab, Tabs } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { dataURItoBlob } from '../utils/dataUrlToBlob';
import { create, getAll } from '../api/Image';
import { LoadingContext } from '../hoc/loading';
import { MessageContext } from '../hoc/messageNotification';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const AddImage = ({ data, handleClose, handleImageSelect }) => {
  const label = 'Please, upload image 1140x640';
  const { editor, onReady } = useFabricJSEditor();
  const [value, setValue] = useState(0);
  const [posts, setPosts] = useState(null);
  const [fileName, setFileName] = useState(''); 
  const [imgDataUrl, setImgDataUrl] = useState('');

  const { setLoading } = useContext(LoadingContext);
  const { setErrorMessage } = useContext(MessageContext);

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const clearCanvas = () => {
    editor.canvas.clear();
    editor.canvas.renderAll();
    editor.canvas.remove(...editor.canvas.getObjects());
  };

  const getPosts = async () => {
    try {
      setLoading(true);
      const res = await getAll();
      setPosts(res);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPosts();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChange = (imageList) => {
    const imgObj = new Image();
    setImages(imageList);
    setFileName(imageList[0].file.name)
    const { dataUrl } = imageList[0];
    setImgDataUrl(dataUrl)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      setErrorMessage(null);

      const image = imgDataUrl;
      const input = new FormData();
      const file = new Image();
      file.src = image;
      const getFile = await dataURItoBlob(image);
      input.append('file', getFile);
      input.append('name', fileName);
      const res = await create(input);
      handleImageSelect(res.url)
      handleClose()
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
    handleClose();
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box
      className={'add-image'}
      style={{ position: 'relative' }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-placeholder="basic tabs example"
          sx={{
            flexDirection: 'column',
          }}
        >
          <Tab label="Upload new Image" {...a11yProps(0)} />
          <Tab label="Choose from gallery" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Typography fontSize={22} display='flex' justifyContent='center' sx={{my: 3}} component="h3">
          {label}
        </Typography>
        <ImageUploading value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="dataUrl">
          {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
            <Box
              className={images.length ? 'load hidden' : 'load'}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button variant="contained" style={isDragging ? { color: '#2065D1' } : {backgroundColor: "#2065D1", borderRadius: "8px"}} onClick={onImageUpload} {...dragProps}>
                Click or Drop here
              </Button>
              &nbsp;
              {imageList.map((image, index) => (
                <div key={index} className="image-item">
                  <img src={image.dataUrl} alt="" width="640" />
                  <div className="image-item__btn-wrapper">
                    <Button variant="contained" style={{backgroundColor: "#2065D1", borderRadius: "8px", marginTop: "10px"}} onClick={() => onImageRemove(index)}>Remove</Button>
                  </div>
                </div>
              ))}
            </Box>
          )}
        </ImageUploading>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Button type="submit" sx={{my: 2}} fullWidth variant="contained">
            Add
          </Button>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: "700px",
            flexWrap: "wrap",
            height: "500px",
            overflowY: "scroll"
          }}
        >
          {posts && posts.map((e, i) =>
          <div key={e.id} role="button" style={{margin: "5px"}} tabIndex={i} onClick={() => {handleImageSelect(e.url); handleClose()}} onKeyDown={() => null}>
            <img src={e.url} width={150} height={100}  alt="img-post" />
          </div>
           )}
        </Box>
      </TabPanel>
    </Box>
  );
};
