import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, MenuItem, TextField, FormControl, InputLabel, Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import { getById, getInfoById, update } from '../../api/User';
import { MessageContext } from '../../hoc/messageNotification';
import { LoadingContext } from '../../hoc/loading';

// ----------------------------------------------------------------------

export default function UserEdit() {
  const navigate = useNavigate();

  const [userSubscriptionInfo, setUserSubscriptionInfo] = useState();
  const [role, setRole] = useState()
  const [gender, setGender] = useState();

  const { setErrorMessage } = useContext(MessageContext);
  const { setLoading } = useContext(LoadingContext);

  const UserEditSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address'),
  });

  const { id } = useParams();

  const getUserById = async () => {
    try {
      setLoading(true);
      const res = await getById(id);
      const resInfoSubscription = await getInfoById(id);
      setRole(res.role)
      setGender(res.gender)
      setUserSubscriptionInfo(resInfoSubscription)
      setValues(res);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const handleSelectChange = (e, type) => {
    switch (type) {
      case 'gender':
        setGender(e.target.value)
        values.gender = e.target.value;
        break;
      case 'role':
        setRole(e.target.value)
        values.role = e.target.value;
        break;
      default:
        break;
    }
  };

  const updateUser = async () => {
    try {
      setLoading(true);
      await update(values);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserById();
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      fullName: '',
      username: '',
      role: '',
      gender: '',
      id: '',
    },
    validationSchema: UserEditSchema,
    onSubmit: () => {
      updateUser();
      navigate('/dashboard/user');
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setValues } = formik;

  const inputs = [
    { id: 'fullName', label: 'Full Name' },
    { id: 'email', label: 'Email' },
    { id: 'username', label: 'Username' },
  ];

  return (
    <FormikProvider value={formik}>
      <Stack spacing={3}>
        <h1>Edit user</h1>
      </Stack>
      <Form noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {inputs.map((e) => (
            <TextField
              key={e.id}
              fullWidth
              autoComplete={e.id}
              type="text"
              placeholder={e.label}
              {...getFieldProps(e.id)}
              error={Boolean(touched[e.id] && errors[e.id])}
              helperText={touched[e.id] && errors[e.id]}
            />
          ))}
          <FormControl fullWidth>
            <InputLabel id={`select-role`}>Role</InputLabel>
            <Select
              labelId={`select-role`}
              label={'Role'}
              value={values.role}
              defaultValue={values.role}
              onChange={(e) => handleSelectChange(e, 'role')}
            >
              <MenuItem value={'ADMIN'}>Admin</MenuItem>
              <MenuItem value={'FREE_USER'}>Free User</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id={`select-gender`}>Identity</InputLabel>
            <Select
              labelId={`select-gender`}
              label={'Identity'}
              value={values.gender}
              defaultValue={values.gender}
              onChange={(e) => handleSelectChange(e, 'gender')}
            >
              <MenuItem value={'MALE'}>Mr</MenuItem>
              <MenuItem value={'FEMALE'}>Mrs</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack my={3}>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Edit
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
