import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// material
import { Stack, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
// api
import { getInfoById } from '../../api/User';
import { LoadingContext } from '../../hoc/loading';
import { MessageContext } from '../../hoc/messageNotification';

const DarkerDisabledTextField = withStyles({
  root: {
    marginRight: 8,
    '& .MuiInputBase-root.Mui-disabled': {
      margin: '10px 5px',
      color: 'rgb(0, 0, 0)',
    },
  },
})(TextField);

export const UserView = () => {
  const [user, setUser] = useState({});
  const { setLoading } = useContext(LoadingContext);
  const { setErrorMessage } = useContext(MessageContext);

  const { id } = useParams();

  const getUserById = async () => {
    try {
      setLoading(true);
      const res = await getInfoById(id);
      setUser(res);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserById();
  }, []);

  return (
    <>
      <Stack spacing={3} sx={{ marginBottom: '20px' }}>
        <h1>User overview</h1>
      </Stack>
      <Stack>
        <DarkerDisabledTextField
          fullWidth
          disabled
          value={user?.fullName ? user?.fullName : 'No full name set'}
          type="text"
          label="Full Name"
          variant="outlined"
        />
        <DarkerDisabledTextField
          fullWidth
          disabled
          value={user?.username ? user?.username : 'No username set'}
          type="text"
          label="Username"
          variant="outlined"
        />
        <DarkerDisabledTextField
          fullWidth
          disabled
          value={user?.email ? user?.email : 'No email set'}
          type="text"
          label="Email"
          variant="outlined"
        />
        <DarkerDisabledTextField
          fullWidth
          disabled
          value={user.active ? user?.active.toString() : 'Not active'}
          type="text"
          label="Active"
          variant="outlined"
        />
        <DarkerDisabledTextField
          fullWidth
          disabled
          value={user?.gender || 'No identity set'}
          type="text"
          label="Identity"
          variant="outlined"
        />
        <DarkerDisabledTextField
          fullWidth
          disabled
          value={user?.role || 'No role set'}
          type="text"
          label="Role"
          variant="outlined"
        />
        {/* <DarkerDisabledTextField
          fullWidth
          disabled
          value={user?.fullName}
          type="text"
          label="Full Name"
          variant='outlined'
        /> */}
        <DarkerDisabledTextField
          fullWidth
          disabled
          value={user?.stripeId ? 'Yes' : 'No'}
          type="text"
          label="Stripe connected"
          variant="outlined"
        />
        <DarkerDisabledTextField
          fullWidth
          disabled
          value={user?.lastLogin ? new Date(user?.lastLogin).toISOString().split('.')[0].replace('T', " ").slice(0, -3) : 'User have not been into account'}
          type="text"
          label="Last login"
          variant="outlined"
        />
        <DarkerDisabledTextField
          fullWidth
          disabled
          value={user?.registrationDate ? new Date(user?.registrationDate).toISOString().split('.')[0].replace('T', " ").slice(0, -3) : ''}
          type="text"
          label="Registration date"
          variant="outlined"
        />
        {user.payments && user.payments.length > 0 ? (
          <>
          <DarkerDisabledTextField
          fullWidth
          disabled
          value={user ? user?.payments[0].userSubscription.title : 'No plan'}
          type="text"
          label="Subscription plan"
          variant="outlined"
        />
        <DarkerDisabledTextField
          fullWidth
          disabled
          value={user ? user?.payments[0].userSubscription.period : ''}
          type="text"
          label="Period"
          variant="outlined"
        />
        <DarkerDisabledTextField
          fullWidth
          disabled
          value={user ? user?.payments[0].userSubscription.expired : ''}
          type="text"
          label="Expiration date"
          variant="outlined"
        />
          </>
        ) : (
          <DarkerDisabledTextField
          fullWidth
          disabled
          value={'User have no payment history'}
          type="text"
          label="Payment history"
          variant="outlined"
        />
        )}
      </Stack>
    </>
  );
};
