import React, { useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import { Alert } from "@mui/lab"

export const MessageContext = React.createContext("")

const useStyles = makeStyles(theme => ({
  alert: {
    position: "fixed",
    bottom: "20px",
    left: "50%",
    zIndex: "999",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgb(255, 231, 236) !important",
  },
  success: {
    position: "fixed",
    bottom: "50%",
    left: "50%",
    zIndex: "999",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgb(206, 255, 189) !important",
  }
}))

export const MessageNotificationHOC = ({ children }) => {
  const classes = useStyles()
  const [MessageContextData, setMessageContextData] = useState("")
  const [successMessageContextData, setSuccessMessageContextData] = useState()

  useEffect(() => {
    console.log("successMessageContextData", successMessageContextData)
    if (MessageContextData) {
      setTimeout(() => {
        setMessageContextData("")
      }, 5000)
    } else if (successMessageContextData) {
      setTimeout(() => {
        setSuccessMessageContextData({})
      }, 5000)
    }
  }, [MessageContextData, successMessageContextData])
  return (
    <MessageContext.Provider
      value={{
        errorMessage: MessageContextData,
        setErrorMessage: setMessageContextData,
        successMessage: successMessageContextData,
        setSuccessMessage: setSuccessMessageContextData
      }}
    >
      {children}
      {MessageContextData && typeof MessageContextData === 'string' && (
        <Alert className={classes.alert} severity="error">
          {" "}
          {MessageContextData.toString()}
        </Alert>
      )}
      {MessageContextData && typeof MessageContextData === Object (
         <Alert className={classes.error} severity="error">
          {" "}
          {MessageContextData?.message}
        </Alert>
      )
      }
       {successMessageContextData && typeof successMessageContextData === 'string' && (
        <Alert className={classes.success} >
          {" "}
          {successMessageContextData.toString()}
        </Alert>
      )}
      {successMessageContextData && 
        Object.keys(successMessageContextData).map(e => <Alert className={classes.success}>
          {" "}
          {e}
        </Alert> )} 
    </MessageContext.Provider>
  )
}
