import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  FormControlLabel,
  Autocomplete,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MessageContext } from '../../hoc/messageNotification';
import { CreatePrice } from '../../components/CreatePrice';
// api
import { getAll as getCategorieslist } from '../../api/Category';
import { getAll as getViewAccessTypesList } from '../../api/ViewAccessSubscription';
import { create } from '../../api/Subscription';
import { create as uploadImage } from '../../api/Image';
import { LoadingContext } from '../../hoc/loading';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export default function CreateSubscription() {
  const { setLoading } = useContext(LoadingContext);
  const { setErrorMessage } = useContext(MessageContext);

  const [active, setActive] = useState(false);
  const [viewAccess, setViewAccess] = useState();
  const [viewAccessList, setViewAccessList] = useState();
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [responseSubscription, setResponseSubscription] = useState();

  const SubscriptionSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    subtitle: Yup.string().required('Subtitle is required'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      subtitle: '',
      active: false,
      categories: [],
      prices: [],
      image: null,
      subscription: {},
    },
    validationSchema: SubscriptionSchema,
    onSubmit: () => createSubscription(),
  });

  const createSubscription = async () => {
    try {
      setLoading(true);
      if(!values.image) {
        setErrorMessage('Upload image first')
        setTimeout(() => {
          setLoading(false)
        }, 1500)
        return;
      }
      const res = await create(values);
      setResponseSubscription(res);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const getCategories = async () => {
    try {
      setLoading(true);
      const response = await getCategorieslist();
      setCategoriesOptions(response);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const getViewAccessTypes = async () => {
    try {
      setLoading(true);
      const response = await getViewAccessTypesList();
      setViewAccessList(response);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCategories();
    getViewAccessTypes();
  }, []);

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const onCategoriesInputChange = (e, v) => {
    values.categories = v;
  };

  const handleSelectChange = (e, type) => {
    const input = new FormData();
    const file = document.getElementById('file-input').files[0];
    switch (type) {
      case 'viewAccess':
        values.subscription = e.target.value;
        setViewAccess(e.target.value);
        break;
      case 'active':
        values.active = e.target.checked;
        setActive(e.target.checked);
        break;
      case 'image':
        input.append('file', file);
        input.append('name', file.name);
        uploadImage(input).then((res) => {
          values.image = res.url;
        });
        break;
      default:
        break;
    }
  };

  const inputs = [
    { id: 'title', label: 'Title' },
    { id: 'subtitle', label: 'Subtitle' },
  ];

  return (
    <>
      <FormikProvider value={formik}>
        <Stack spacing={3}>
          <h1>Create Subscription</h1>
        </Stack>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Button variant="contained" sx={{ my: 3 }} component="label" startIcon={<Iconify icon="eva:plus-fill" />}>
              Subscription image
              <input type="file" id="file-input" onChange={(e) => handleSelectChange(e, 'image')} hidden />
            </Button>
          <Stack spacing={3}>
            {inputs.map((e) => (
              <TextField
                fullWidth
                key={e.id}
                autoComplete={e.id}
                type="text"
                placeholder={e.label}
                {...getFieldProps(e.id)}
                error={Boolean(touched[e.id] && errors[e.id])}
                helperText={touched[e.id] && errors[e.id]}
              />
            ))}

            <Stack my={3}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  id="categories"
                  options={categoriesOptions}
                  disableCloseOnSelect
                  onChange={(e, v) => onCategoriesInputChange(e, v)}
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option.title}
                    </li>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => <TextField {...params} placeholder="Categories" />}
                />
              </FormControl>
            </Stack>

            <FormControl fullWidth>
              <InputLabel id={`view-access`}>View access</InputLabel>
              <Select
                labelId={`view-access`}
                placeholder="View access"
                value={viewAccess}
                onChange={(e) => handleSelectChange(e, 'viewAccess')}
              >
                {viewAccessList?.map((e) => (
                  <MenuItem key={e.title} value={e}>
                    {e.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControlLabel
              label="Active"
              control={<Checkbox checked={active} onChange={(e) => handleSelectChange(e, 'active')} />}
            />
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Create
          </LoadingButton>
        </Form>
      </FormikProvider>
      {responseSubscription ? (
        <Stack direction="column" sx={{ my: 2 }}>
          <CreatePrice subscription={responseSubscription} />
        </Stack>
      ) : (
        ''
      )}
    </>
  );
}
