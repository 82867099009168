import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import { getById, giftSubscription } from '../../api/User';
import { MessageContext } from '../../hoc/messageNotification';
import { LoadingContext } from '../../hoc/loading';
import { getAll } from '../../api/Subscription';

// ----------------------------------------------------------------------

export default function UserSubscriptionGift() {
  const navigate = useNavigate();

  const [subscriptions, setSubscriptions] = useState();
  const [selectedSubscription, setSelectedSubscription] = useState();
  const [period, setPeriod] = useState();

  const { setErrorMessage } = useContext(MessageContext);
  const { setLoading } = useContext(LoadingContext);

  const { id } = useParams();

  const getSubscriptions = async () => {
    try {
      setLoading(true)
      const res = await getAll()
      setSubscriptions(res)
    } catch (e) {
      setErrorMessage(e)
    }
    setLoading(false)
  }

  const handleSelectChange = (e, type) => {
    switch (type) {
      case 'period':
        setPeriod(e.target.value);
        values.period = e.target.value;
        break;
      case 'subscription':
        setSelectedSubscription(e.target.value)
        values.stripeProductId = e.target.value;
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getSubscriptions()
    getUserById();
  }, [])

  const giftSubscriptionToUser = async () => {
    try {
      setLoading(true);
      console.log(values)
      await giftSubscription({email: values.email, period: values.period, stripeProductId: values.stripeProductId});
      navigate('/dashboard/user');
    } catch (e) {
      setErrorMessage(e);
      setSubmitting(false)
    }
    setLoading(false);
  };

  const getUserById = async () => {
    try {
      setLoading(true);
      const res = await getById(id);
      console.log(res)
      setValues(res);
    } catch (e) {
      setErrorMessage(e);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
        email: '',
        stripeProductId: '',
        period: ''
    },
    onSubmit: () => {
      giftSubscriptionToUser();
    },
  });

  const { values, isSubmitting, setSubmitting, setValues, handleSubmit } = formik;

  return (
    <FormikProvider value={formik}>
      <Stack spacing={3}>
        <h1>Choose subscription period</h1>
      </Stack>
      <Form noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <FormControl fullWidth>
            <InputLabel id={`select-period`}>Period</InputLabel>
            <Select
              labelId={`select-period`}
              label={'Period'}
              value={values.period}
              onChange={(e) => handleSelectChange(e, 'period')}
            >
              <MenuItem value={'MONTH'}>Month</MenuItem>
              <MenuItem value={'YEAR'}>Year</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id={`select-subscription`}>Subscription</InputLabel>
            <Select
              labelId={`select-subscription`}
              label={'Subscription'}
              value={values.stripeProductId}
              onChange={(e) => handleSelectChange(e, 'subscription')}
            >
              {subscriptions?.map(e => <MenuItem key={e.stripeProductId} value={e.stripeProductId}>{e.title}</MenuItem>)}
            </Select>
          </FormControl>
        </Stack>
        <Stack my={3}>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Gift
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
